<div id="standard-game-container" class="row">
    <div class="col-sm-2" *ngIf="getOpening() && getOpening().isParsed()">
        <!-- <div *ngFor="let chapter of getOpening().getChapters()">
            <button
                (click)="getOpening().setCurrentChapter(chapter); resetBoard()"
            >
                {{ chapter.title }}
            </button>
        </div> -->
    </div>
    <div class="col-sm-8">
        <div class="row" style="background-color: rgb(209, 195, 0)">
            <h2>
                This feature is in <strong>BETA</strong> and is not yet
                complete.
            </h2>
            <h5>
                Feel free to play around, but do not expect full functionality
                at this time.
            </h5>
        </div>
        <app-board-overlay
            id="board-overlay"
            *ngIf="getShowBoardOverlay()"
            [boardOverlayData]="getBoardOverlayData()"
            (boardOverlayEmitter)="boardOverlayEvent($event)"
        ></app-board-overlay>
        <app-game
            #gameComponent
            id="game"
            [game]="getGame()"
            [config]="gameConfig"
            (gameDataEmitter)="gameDataEvent($event)"
        ></app-game>
    </div>
    <!-- <div class="break-div col-sm-4"></div> -->
    <div class="col-sm-2">
        <app-game-details-table
            id="game-details"
            [pgn]="getGame().pgn"
            (navigationDataEmitter)="navigationDataEvent($event)"
        ></app-game-details-table>
        <div
            *ngIf="getNotificationVisibility()"
            id="opening-training-alert"
            class="alert {{ getAlertType() }}"
            role="alert"
        >
            <h4 class="alert-heading">{{ getChoiceHeadingMessage() }}</h4>
            <p>
                {{ getChoiceSubMessage() }}
            </p>
            <hr *ngIf="getChoiceQuote()" />
            <blockquote *ngIf="getChoiceQuote()" class="blockquote">
                <p class="mb-0 quote-font-size">{{ getChoiceQuote() }}</p>
                <!-- <footer class="blockquote-footer">
                    {{ getChoiceAuthor() }}
                </footer> -->
            </blockquote>
        </div>
    </div>
</div>
<!-- <div *ngIf="getOpening()" id="developer-output">
    <p>
        {{
            getOpening()
                ? getOpening().getOptionsFromBranch(
                      getOpening().getCurrentChapter().index
                  )
                : null
        }}
    </p>
    <hr />
    <p
        *ngFor="
            let s of getOpening().developerData(
                getOpening().getCurrentChapter().index
            )
        "
    >
        {{ s }}
    </p>
</div> -->
