<div class="row about-section justify-content-sm-center">
    <div class="col-sm-10">
        <div id="carousel" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <!-- <li
                    data-target="#carousel"
                    data-slide-to="0"
                    class="active"
                ></li> -->
                <li
                    *ngFor="let carouselItem of carouselItems; index as i"
                    [class.active]="i === 0"
                ></li>
            </ol>
            <div class="carousel-inner">
                <div
                    *ngFor="let carouselItem of carouselItems; index as i"
                    class="carousel-item"
                    [class.active]="i === 0"
                >
                    <img class="d-block w-100" alt="" />
                    <div class="carousel-caption">
                        <h2>{{ carouselItem.title }}</h2>
                        <p>
                            {{ carouselItem.details }}
                        </p>
                        <p *ngIf="i === 3">
                            ...even feel free to directly
                            <a href="https://www.ragchess.com/contact/"
                                >email our marketing guy, Nick!</a
                            >
                        </p>
                    </div>
                </div>
            </div>
            <a
                class="carousel-control-prev"
                href="#carousel"
                role="button"
                data-slide="prev"
            >
                <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
            </a>
            <a
                class="carousel-control-next"
                href="#carousel"
                role="button"
                data-slide="next"
            >
                <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
    <!-- <div class="col-sm-1"></div>
    <div class="col-sm-5">
        <h3></h3>
        <h6>
            
        </h6>
        <h3></h3>
        <h6>
            
        </h6>
        <h6>
            
        </h6>
        <h6>
            
        </h6>
    </div>
    <div class="col-sm-1"></div>
    <div class="col-sm-4">
        <h3></h3>
        <h6>
            
        </h6>
        <h3></h3>
        <h6 style="text-align: center">
            
        </h6>
    </div>
    <div class="col-sm-1"></div> -->
</div>

<div class="row our-team-section justify-content-sm-center">
    <div class="col-sm-12"><h2>Our Team</h2></div>
    <div *ngFor="let staff of staffMembers" class="col-sm-4">
        <div style="margin-right: auto; margin-left: auto">
            <img src="{{ staff.url }}" alt="staff image" />
        </div>
        <h4>{{ staff.name }}</h4>
        <h5>{{ staff.title }}</h5>
        <p>{{ staff.bio }}</p>
        <h5>
            lichess.org ::
            <a href="https://lichess.org/@/{{ staff.lichess }}" target="_blank"
                >@{{ staff.lichess }}</a
            >
        </h5>
        <h5>
            discord ::
            <a href="{{ staff.discordLink }}" target="_blank"
                >@{{ staff.discord }}</a
            >
        </h5>
    </div>
</div>
