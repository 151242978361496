<div
    #gameContainer
    id="game-container"
    class="row"
    (window:resize)="resizeBoard($event)"
>
    <div class="col-auto">
        <canvas id="board" width="{{ boardSize }}" height="{{ boardSize }}"
            >canvas</canvas
        >
    </div>
</div>
