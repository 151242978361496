<div id="course-cards" class="cards" [class.hide]="lessons.length !== 0">
    <a
        *ngFor="let entry of courseKey"
        [routerLink]="['courses']"
        [queryParams]="entry[0]"
        class="card"
    >
        <h2>{{ entry[1] }}</h2>
        <p>{{ entry[2] }}</p>
    </a>
</div>

<app-puzzles
    #puzzlesComponent
    [_puzzles]="lessons"
    [class.hide]="lessons.length === 0"
></app-puzzles>
