<div>
    <!-- <p *ngIf="pgn" style="color: white;">{{ pgn }}</p> -->
    <div id="table-container">
        <table id="table">
            <th class="th-number">
                Moves
            </th>
            <th class="th-move">
                White
            </th>
            <th class="th-move">
                Black
            </th>
            <tr *ngFor="let line of getPGNArray()">
                <td class="number">{{ line.number }}</td>
                <td class="move">{{ line.white }}</td>
                <td class="move">{{ line.black }}</td>
            </tr>
        </table>
    </div>
    <button (click)="emitNavigation('back')" class="toggle-button">
        &#60;&#60;
    </button>
    <button (click)="emitNavigation('forward')" class="toggle-button">
        &#62;&#62;
    </button>
</div>
